import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/seo"

const LearnPage = () => (
  <Layout>
    <Seo
      title="Crypto, Blockchain, Web3 Development Tutorials"
    />
    <div className="max-w-7xl mx-auto px-4 mt-6 mb-24">
      <div className="">
        <h1 className="text-gray-300 text-xl md:text-2xl tracking-wider font-semibold">Learn - <span className="italic">Coming Soon</span></h1>
      </div>

      <div className="mt-16">
        <h2 className="text-gray-200 text-5xl lg:text-7xl font-black">How to become a Blockchain Developer, Cryptocurrency Developer and Web3 Developer</h2>
        <h3 className="mt-10 text-blue-500 text-2xl lg:text-3xl">Ultimate list of free and premium crypto, blockchain and web3 tutorials and courses</h3>
      </div>

      <div className="mt-16">
        <div className="flex flex-col md:flex-row items-center md:space-x-4 space-y-4 md:space-y-0">
          <Link
            to="/jobs"
            className="w-full md:w-auto text-indigo-100 bg-indigo-600 hover:bg-indigo-700 hover:text-white px-10 py-4 rounded-md text-center text-xl lg:text-2xl font-semibold"
          >
            Find a job
          </Link>
          <Link
            to="/post-a-job"
            className="w-full md:w-auto text-gray-400 bg-site-color-brand-1 hover:bg-site-color-brand-3 hover:text-white px-10 py-4 rounded-md text-center text-xl lg:text-2xl font-semibold"
          >
            Post a job
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default LearnPage
